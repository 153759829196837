<template>
  <vs-row>
    <vs-col vs-w="12">
      <span v-if="$store.state.cartSale.cartItems.length >= 1" style="position: absolute;top: 15%;left: 7%;color: white;padding: 4px 0;background-color: red;border-radius: 20px;width: 30px;height: 30px;text-align: center !important;">
        {{$store.state.cartSale.cartItems.length}}
      </span>
      <div style="display: flex"  class="grid grid-cols-4 ">
        <div class=" flex  justify-center items-center" >
          <img
            src="@/assets/images/icons/cart.png"
            alt=""
            width="64"
            class="cart-icon img-contain"
          />
          
        </div>
        <!-- <h1 class="mb-4 pl-4 pt-2 text-title">
          สินค้าในตะกร้า
        </h1> -->
        <div class="col-span-3" >
          <slot name="card-result"></slot>
        </div>

      </div>
    </vs-col>
    <vs-col vs-w="12">
      <VuePerfectScrollbar
        class="scroll-area"
        :settings="settings"
        :class="['heightControl']"

      >
        <vx-card
          class="cardx mb-2"
          v-for="(item, index) in cartItems"
          :key="index"
        >
          <div class="grid grid-cols-4 ">
            <!-- top left -->
            <div class="col-span-2">
              <img
                :src="item.info.mainImage"
                alt="product"
                width="100"
                class="object-contain"
              />
            </div>
            <!-- top right -->
            <div class="col-span-2">
              <div class="grid grid-cols-2 ">
                <div class="col-span-2">
                  <div class="flex items-start" >
                    <h4 style="color: #2074FF; font-size: 1.2rem">{{ item.info.name }}</h4>
                  </div>
                    <div style="font-size: 0.8rem" v-if="item.info.code" > ({{ item.info.code }}) </div>

                </div>
              </div>
            </div>
            <!-- bottom -->
            <div class="col-span-2">
              <!--              <p>ประเภท: ไม่มี</p>-->
              <div
                v-if="item.info.color !== undefined && item.info.color !== null"
              >
                <p v-if="item.info.color.length > 0">
                  สี: {{ getName(allInfo.colors, 'code' , item.info.color , 'description')  }}
                </p>
              </div>
              <div
                v-if="item.info.size !== undefined && item.info.size !== null"
              >
                <p v-if="item.info.size.length > 0">
                  ไซส์: {{ item.info.size }}
                </p>
              </div>
              <!-- <div
                v-if="item.info.code !== undefined && item.info.code !== null"
              >
                <p v-if="item.info.code.length > 0">
                  รหัส: {{ item.info.code }}
                </p>
              </div> -->
            </div>
            <div class="col-span-2">
              <table class="cart-table">
                <tbody>
                  <tr class="text-success">
                    <td>ราคา:</td>
                    <td class="pl-1">
                      {{ item.info.price.toLocaleString() }}
                    </td>
                  </tr>
                  <tr
                    class="text-success"
                    v-if="item.info.bail !== undefined && item.info.bail !== null"
                  >
                    <td>ค่าประกัน:</td>
                    <td class="pl-1">{{ item.info.bail.toLocaleString() }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="col-span-2">
            <div class="grid grid-cols-3">
              <div
                class="text-center col-span-3 sm:col-span-1 md:col-span-1 lg:col-span-1 xl:col-span-1 mb-2 mt-4"
              >
                <div class="flex flex-col items-center">
                  <vue-number-input
                    :value="item.info.quantity"
                    @change="updateQuantity(item.id, $event)"
                    :min="1"
                    size="small"
                    inline
                    controls
                    center
                  ></vue-number-input>
                </div>
              </div>
              <div
                class="col-span-3 sm:col-span-2 md:col-span-2 lg:col-span-2 xl:col-span-2 mb-2"
              >
                <div class="flex flex-col items-center">
                  <vs-button
                    color="danger"
                    type="filled"
                    class="rounded-full w-10/12 xl:w-9/12 mt-4 text-white cursor-default"
                    v-if="item.info.notAvailable"
                  >
                    ติดคิว
                  </vs-button>

                  <vs-button
                    color="danger"
                    type="border"
                    class="btn-remove w-10/12 xl:w-9/12 mt-4"
                    @click="removeItemFromCart(item.id)"
                  >
                    <font-awesome-icon icon="times" />
                    Remove
                  </vs-button>
                </div>
              </div>
            </div>
          </div>
        </vx-card>
      </VuePerfectScrollbar>


    </vs-col>
  </vs-row>
</template>

<script>
import VueNumberInput from "@chenfengyuan/vue-number-input";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import shapeFormat from "@/mixins/shapeFormat.js";

export default {
  mixins: [shapeFormat],
  components: { VueNumberInput, VuePerfectScrollbar },
  computed: {
    cartItems: {
      get() {
        return this.$store.state.cartSale.cartItems;
      }
    },

  },
  data() {
    return {
      allInfo: {
        categories: [],
        colors: [],
        sizes: [],
        textures: []
      },
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      },
      count: 1,
      windowHeight: window.innerHeight,
      cartHeight: 0,
      txt: ''
    };
  },
   watch: {
        windowHeight(newHeight, oldHeight) {
            this.txt = `it changed to ${newHeight} from ${oldHeight}`;
            this.cartHeight = newHeight - 141 - 103;
            console.log("windowHeight >>> ",newHeight - 141 - 103 );

        }

    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },


    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        });

    },

    async created(){
      await this.fetchAllInfo();
    },

  methods: {

    async fetchAllInfo() {
      // const config = this.$store.state.auth.apiHeader;

      await  this.$store.dispatch("productGroup/getAllInfo");

      this.allInfo.categories = this.$store.getters["productGroup/categories"];
      this.allInfo.textures = this.$store.getters["productGroup/textures"];
      this.allInfo.colors =  this.$store.getters["productGroup/colors"];
      this.allInfo.sizes = this.$store.getters["productGroup/sizes"];

    },
    onResize() {
            this.windowHeight = window.innerHeight
        },
    removeItemFromCart(productId) {
      this.$store.dispatch("cartSale/removeCartItem", {
        id: productId
      });
    },
    updateQuantity(itemId, value) {
      this.$store.dispatch("cartSale/updateQtyCart", {
        id: itemId,
        quantity: value
      });
    }
  }
};
</script>

<style scoped>
.text-title {
  font-size: 26px;
}

.cart-icon {
  width: 42px;
}

.cart-table td {
  font-size: 14px;
}

.card-total-price {
  display: flex;
  justify-content: center;
}
.heightControl{
  height: 400px;
}

.btn-remove {
  background-color: #f2f2f2 !important;
  border-color: #f2f2f2;
  color: #999999;
}

.img-cover {
  object-fit: cover;
}

.img-contain {
  object-fit: contain;
}


@media only screen and (min-height: 900px)  {

  .heightControl{
      height: 650px !important;
    }
}

</style>

<style lang="scss" scoped>
.scroll-area {
  position: relative;
  margin: auto;
}

.cardx {
  box-shadow: none !important;
  border: 1px solid #f2f2f2;
}
</style>
