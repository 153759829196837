<template>
  <vs-row class="sale-order-product-category">
    <vs-col vs-xs="12" vs-sm="12" vs-lg="8" class="py-4">
      <vx-card>
        <vs-row class="py-4">
          <vs-col vs-xs="12" vs-sm="12" vs-md="12" vs-lg="12">
            <div class="flex justify-end">
              <vs-button
                :style="{
                  border: '1px solid #2196F3',
                  color:
                    toggleDisplay === true
                      ? '#2196F3 !important'
                      : '#ffffff !important',
                  backgroundColor:
                    toggleDisplay === false
                      ? '#7366f0 !important'
                      : '#ffffff !important',
                  borderRadius: 0
                }"
                @click="onChangeToggleDisplay(false)"
              >
                สินค้าขาย
              </vs-button>
              <!-- <vs-button
                :style="{
                  border: '1px solid #2196F3',
                  color:
                    toggleDisplay === false
                      ? '#2196F3 !important'
                      : '#ffffff !important',
                  backgroundColor:
                    toggleDisplay === true
                      ? '#7366f0 !important'
                      : '#ffffff !important',
                  borderRadius: 0
                }"
                @click="onChangeToggleDisplay(true)"
              >
                Voucher
              </vs-button> -->
            </div>
          </vs-col>
        </vs-row>
        <vs-row class="pt-4 px-2">
          <vs-col vs-xs="12" vs-sm="8" vs-md="8" vs-lg="8">
            <div class="flex">
              <vs-button
                v-if="toggleDisplay === false"
                color="success"
                type="filled"
                @click="addProduct"
                class="mr-4"
              >
                <font-awesome-icon icon="gift" />
                เพิ่มสินค้า
              </vs-button>
              <vs-button v-if="toggleDisplay === true" color="warning" type="filled" @click="addVoucher">
                สร้าง Voucher
              </vs-button>
            </div>
          </vs-col>
          <vs-col vs-w="12" vs-sm="4" vs-md="4" vs-lg="4">
            <div class="con-input-search vs-table--search">
              <input
                type="text"
                class="input-search vs-table--search-input"
                style="height: 40px; font-size: 16px"
                @keyup="querySearch"
                v-model="searchKeyword"
              /><i class="vs-icon notranslate icon-scale material-icons null"
                >search</i
              >
            </div>
          </vs-col>
        </vs-row>

        <vs-row vs-justify="left" class="pt-8">
          <VuePerfectScrollbar class="scroll-area" :settings="settings">
            <vs-col
              type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="3"
              vs-xs="6"
              vs-sm="4"
              vs-lg="4"
              v-for="(item, index) in displayItems"
              :key="index"
              class="px-2"
            >
              <vs-card class="cardx">
                <div slot="media" class="px-4 py-4">
                  <font-awesome-icon
                    icon="times"
                    size="lg"
                    class="text-danger float-right cursor-pointer"
                    @click="removeItem(item, index)"
                  />
                  <img
                    :src="item.mainImage"
                    height="150"
                    style="object-fit: contain"
                  />
                  <div class="text-center pt-4" style="height: 60px">
                    <h4>{{ item.name }}</h4>
                    <!-- <small>({{ item.code }})</small><br />
                    <small style="font-size: 14px"
                      >สี: {{ item.color }} ไซส์: {{ item.size }}</small
                    > -->
                  </div>
                </div>
                <div class="flex justify-center">
                  <table class="product-catalog-table-detail">
                    <tbody>
                      <tr>
                        <td>คงเหลือ:</td>
                        <td class="pl-2">{{ item.inStock.toLocaleString() }}</td>
                      </tr>
                      <tr>
                        <td>ราคา:</td>
                        <td class="pl-2">{{ item.price.toLocaleString() }}</td>
                      </tr>
                      <tr v-if="item.value !== undefined">
                        <td>มูลค่า:</td>
                        <td class="pl-2">{{ item.value.toLocaleString() }}</td>
                      </tr>
                      <!-- <tr>
                      <td>ค่าประกัน:</td>
                      <td class="pl-2">0</td>
                    </tr> -->
                    </tbody>
                  </table>
                </div>
                <vs-button
                  color="primary"
                  type="filled"
                  class="btn-filter w-full"
                  style="font-size: 10px"
                  :disabled="item.inStock === 0"
                  @click="addItemToCart(item)"
                >
                  <font-awesome-icon icon="shopping-cart" class="mr-2" />
                  <span v-if="item.inStock > 0">Add to Cart</span>
                  <span v-else>Out of Stock</span>
                </vs-button>
                <div slot="footer"></div>
              </vs-card>
            </vs-col>
          </VuePerfectScrollbar>
        </vs-row>
        <vs-row class="py-4">
          <vs-col vs-xs="10" vs-sm="11" vs-lg="11" class="px-2">
            <vs-pagination
              :total="totalPaginate"
              v-model="currentPaginate"
              class="cursor-pointer"
            ></vs-pagination>
          </vs-col>
          <vs-col vs-xs="2" vs-sm="1" vs-lg="1" class="px-2">
            <vs-input
              readonly
              :value="pagination.pageSize"
              class="input-display-items w-full"
              style="width: 40px !important"
            />
          </vs-col>
        </vs-row>
      </vx-card>
    </vs-col>

    <!-- CartForSale -->
    <vs-col v-if="windowWidth > 900" :class="[$store.state.reduceButton==true ? 'reduceBtnTrue' :  'reduceBtnFalse']" 
          style="position:fixed; top:15%; left: 68%;  padding-bottom: 100px !important;" vs-xs="12" vs-sm="12" vs-lg="4" class="px-4 py-4">
      <CartForSale>
        <template v-slot:card-result>
          <vx-card class="my-4" v-if="$store.state.cartSale.cartItems.length > 0">
            <div class="card-total-price flex justify-center">
              <table class="cart-table">
                <tbody>
                  <tr class="text-primary">
                    <td>ยอดรวม</td>
                    <td class="pl-2">
                      {{ $store.state.cartSale.cartTotalPrice.toLocaleString() }}
                      บาท
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="text-center mt-4">
              <vs-button color="danger" type="filled" @click="popupPayment">
                ชำระเงิน
              </vs-button>
            </div>
          </vx-card>
        </template>
      </CartForSale>
    </vs-col>


    <!-- FOR MOBILE -->
    <vs-col vs-xs="12" vs-sm="12" vs-lg="4"
        v-if="windowWidth <= 900"
        style="  padding-bottom: 100px !important;"
        class="px-4 py-4 mb-12 cart-mobile"
        >

        
      <Cart>
        <template v-slot:card-result>
          <vx-card class="my-4" v-if="$store.state.cartSale.cartItems.length > 0">
            <div class="card-total-price flex justify-center">
              <table class="cart-table">
                <tbody>
                  <tr class="text-primary">
                    <td>ยอดรวม</td>
                    <td class="pl-2">
                      {{ $store.state.cartSale.cartTotalPrice.toLocaleString() }}
                      บาท
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="text-center mt-4">
              <vs-button color="danger" type="filled" @click="popupPayment">
                ชำระเงิน
              </vs-button>
            </div>
          </vx-card>
          <!-- <vx-card class="my-4" v-if="cart.cartItems.length > 0"  >
            <div class="card-total-price flex justify-center">

              <table class="cart-table">

                <tbody>
                  <tr class="text-primary">
                    <td>ราคาเช่าสินค้า:</td>
                    <td class="pl-2">
                      {{
                        cart.cartRentalPrice.toLocaleString(
                          "en-US",
                          localeStringConfig
                        )
                      }}
                      บาท
                    </td>
                  </tr>
                  <tr class="text-success">
                    <td>เงินประกัน:</td>
                    <td class="pl-2">
                      {{
                        cart.cartBailPrice.toLocaleString(
                          "en-US",
                          localeStringConfig
                        )
                      }}
                      บาท
                    </td>
                  </tr>
                  <tr>
                    <td>ยอดเงินรวม:</td>
                    <td class="pl-2">
                      {{
                        cart.cartTotalPrice.toLocaleString(
                          "en-US",
                          localeStringConfig
                        )
                      }}
                      บาท
                    </td>
                  </tr>
                </tbody>

              </table>

            </div>
          </vx-card> -->
        </template>
      </Cart>
    </vs-col>
     <!-- CART -->

      <!-- popup คิดเงิน -->
      <vs-popup
        title="รายละเอียดลูกค้า"
        :active.sync="popupPaymentActive"
        :button-close-hidden="true"
      >
        <vs-row>
          <vs-col vs-w="12">
            <vs-row>
              <vs-col
                vs-w="12"
                vs-sm="2"
                vs-md="2"
                vs-lg="2"
                class="flex justify-center items-center"
              >
                เบอร์ติดต่อ
              </vs-col>
              <vs-col vs-w="10" vs-sm="8" vs-md="8" vs-lg="8">
                <vs-input @keypress.enter="getCustomerInfo(customerInfo.phoneNumber)" v-model="customerInfo.phoneNumber" class="w-full" />
              </vs-col>
              <vs-col vs-w="2" class="flex justify-end items-center">
                <vs-button @click="getCustomerInfo(customerInfo.phoneNumber)" color="success">
                  <font-awesome-icon icon="user" style="color: #ffffff" />
                </vs-button>
              </vs-col>
            </vs-row>
            <vs-row class="mt-4">
              <vs-col
                vs-w="12"
                vs-sm="2"
                vs-md="2"
                vs-lg="2"
                class="flex justify-center items-center"
              >
                ชื่อลูกค้า
              </vs-col>
              <vs-col vs-w="12" vs-sm="8" vs-md="8" vs-lg="8">
                <vs-input v-model="customerInfo.name" class="w-full" />
              </vs-col>
            </vs-row>
            <vs-row class="mt-4">
              <vs-col
                vs-w="12"
                vs-sm="2"
                vs-md="2"
                vs-lg="2"
                class="flex justify-center items-center"
              >
                ที่อยู่
              </vs-col>
              <vs-col vs-w="12" vs-sm="8" vs-md="8" vs-lg="8">
                <vs-input v-model="customerInfo.address" class="w-full" />
              </vs-col>
            </vs-row>

            <vs-row class="mt-4" v-if="modalExpand == 'none'">
              <vs-col vs-w="4" class="text-center">
                <font-awesome-icon icon="coins" />
                แต้มคะแนน <span class="text-xl font-bold">{{this.formatPrice(customerInfo.point)}}</span>
              </vs-col>
              <vs-col vs-w="6" class="text-right">
                มูลค่า Voucher <span class="text-xl font-bold">{{this.formatPrice(customerInfo.voucher)}}</span><br />
                <!-- <span class="text-danger">Expired</span> -->
              </vs-col>
            </vs-row>

            <div :style="{ display: modalExpand }">
              <vs-row class="mt-4">
                <vs-col
                  vs-w="12"
                  vs-sm="2"
                  vs-md="2"
                  vs-lg="2"
                  class="flex justify-center items-center"
                >
                  Line ID
                </vs-col>
                <vs-col vs-w="12" vs-sm="8" vs-md="8" vs-lg="8">
                  <vs-input v-model="customerInfo.lineId" class="w-full" />
                </vs-col>
              </vs-row>
              <vs-row class="mt-4">
                <vs-col
                  vs-w="12"
                  vs-sm="2"
                  vs-md="2"
                  vs-lg="2"
                  class="flex justify-center items-center"
                >
                  วันเกิด
                </vs-col>
                <vs-col vs-w="12" vs-sm="8" vs-md="8" vs-lg="8">
                  <v-date-picker
                    v-model="customerInfo.birthDate"
                    :locale="{
                      id: 'th',
                      firstDayOfWeek: 2,
                      masks: { L: 'DD/MM/YYYY' }
                    }"
                  />
                </vs-col>
              </vs-row>
              <vs-row class="mt-4">
                <vs-col
                  vs-w="12"
                  vs-sm="2"
                  vs-md="2"
                  vs-lg="2"
                  class="flex justify-center items-center"
                >
                  รู้จักได้ยังไง
                </vs-col>
                <vs-col vs-w="12" vs-sm="8" vs-md="8" vs-lg="8">
                  <select
                    v-model="customerInfo.howToKnowShop"
                    class="w-full px-2 py-3 rounded"
                    style="border: 1px solid #e0e0e0"
                  >
                    <option value="เว็บไซต์">เว็บไซต์</option>
                    <option value="Facebook">Facebook</option>
                    <option value="Google Ads">Google Ads</option>
                    <option value="บอกต่อ">บอกต่อ</option>
                  </select>
                </vs-col>
              </vs-row>
              <vs-row class="mt-4">
                <vs-col vs-w="4" class="text-center">
                  <font-awesome-icon icon="coins" />
                  แต้มคะแนน <span class="text-xl font-bold">{{this.formatPrice(customerInfo.point)}}</span>
                </vs-col>
                <vs-col vs-w="6" class="text-center">
                  มูลค่า Voucher <span class="text-xl font-bold">{{this.formatPrice(customerInfo.voucher)}}</span><br />
                  <span class="text-danger">Expired</span>
                </vs-col>
              </vs-row>
            </div>


            <vs-row class="mt-4">
              <vs-col vs-w="12">
                <div class="flex justify-center items-center">
                  <vs-button
                    icon
                    class="c-box-shadow"
                    style="background-color: transparent !important; color: #cccccc"
                    @click="modalExpandToggle"
                  >
                    <font-awesome-icon
                      :icon="
                        modalExpand == 'none' ? 'chevron-down' : 'chevron-up'
                      "
                      size="lg"
                    />
                  </vs-button>
                </div>
              </vs-col>
            </vs-row>
            <div class="mt-4 mb-8">
              <hr />
            </div>
            <!-- ใช้แต้มคะแนน -->
            <vs-row class="mt-4">
              <vs-col
                        vs-w="12"
                        vs-sm="4"
                        vs-md="4"
                        vs-lg="4"
                        class="flex justify-center items-center"
                      >
                        วิธีชำระ
                      </vs-col>
              <vs-col
                        vs-w="12"
                        vs-sm="8"
                        vs-md="8"
                        vs-lg="8"
                        class="mb-4"
                      >

                        <select v-model="paymentMethod" class="px-2 py-3 rounded"
                          style="border: 1px solid #e0e0e0; width: 200px" >
                          <option
                            v-for="(item, index) in paymentOptions"
                            :key="`option-rentalpayment-${index}`"
                            :value="item"
                            >{{ item }}</option
                          >
                        </select>
                </vs-col>
            </vs-row>


          <vs-row class="mt-8">
           
            <vs-col vs-w="2" class="text-right">
              <vs-button style="margin:10px;" color="danger" type="border"  @click="popupPaymentActive = false"
                >ยกเลิก
              </vs-button>
            </vs-col>

             <vs-col vs-w="10" class="text-right">
              <vs-button :readOnly="$store.getters['common/buttonLock']" style="margin:10px; width:150px;" color="success" type="filled" class="mr-4" @click="submitPayment"
                >ตกลง
              </vs-button>
            </vs-col>

          </vs-row>

          </vs-col>

        </vs-row>
      </vs-popup>

  </vs-row>
</template>

<script>
import axios from "axios";
import CartForSale from "@/components/backoffice/CartForSale.vue";
import Multiselect from "vue-multiselect";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import shapeFormat from "@/mixins/shapeFormat.js";

export default {
  mixins: [shapeFormat],
  components: { Multiselect, CartForSale, VuePerfectScrollbar },
  computed: {
    somethingChange(){
      return this.$store.getters["productList/somethingChange"];
    },
    currentDate() {
      const dateObj = new Date();
      const month = dateObj.getUTCMonth() + 1; //months from 1-12
      const day = dateObj.getUTCDate();
      const year = dateObj.getUTCFullYear();

      return (
        year +
        "-" +
        month.toString().padStart(2, "0") +
        "-" +
        day.toString().padStart(2, "0")
      );
    }
  },
  data() {
    return {

      windowWidth: window.innerWidth,
      customerInfo: {
        phoneNumber: '',
        name: '',
        address: '',
        lineId: '',
        birthDate: new Date('1800-01-01'),
        howToKnow: '',
        point: 0,
        voucher: 0,
        insuranceDiscount: '',
        insuranceDiscountPercent: '',
        rentalDiscount: '',
        rentalDiscountPercent: '',
        promotionDiscount: 0,
        promotionDiscountDesc: 0,


        paymentInsuranceType: '',
      },
      toggleDisplay: false, // false = product, true = voucher
      displayItems: [],
      pageSize: 12,
      totalPaginate: 0,
      currentPaginate: 1,
      pagination: {},
      searchKeyword: "",
      popupPaymentActive: false,
      paymentInfo: {
        customerName: "",
        customerAddress: "",
        customerPhone: "",
        lineId: "",
        birthDate: "",
        howToKnowShop: "",
        accountNumber: "",
        accountName: "",
        bankName: "",
        paymentMethod: "เงินสด",
        remark: ""
      },
      paymentMethod: "เงินสด",
      paymentOptions: ["ยังไม่ชำระ","เงินสด", "โอนเงิน" , "Alipay/wechat"  , "บัตรเครดิต","ช่องทางอื่น"],

      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      },
      howToKnowOptions: [
        "Google Ads",
        "Facebook",
        "เพื่อนแนะนำ",
        "หน้าเว็บไซต์"
      ],
      howToKnowSelected: "Google Ads",
      modalExpand: "none"
    };
  },
  methods: {

    async getCustomerInfo(phoneNumber){

      let cust = null;
      try{
        cust = await this.checkCustomerByPhoneNumber(phoneNumber);

      }catch(err){
        // this.$swal.fire({
        //     icon: 'warning',
        //     title: 'ไม่มีข้อมูลลูกค้าในฐานข้อมูล',
        //     // text: 'Something went wrong!',
        //   });
        return;
      }
      // this.customerInfo.name = cust.customer.name;
      // this.customerInfo.address = cust.customer.address;
      // this.customerInfo.birthDate = new Date(this.formatDate(cust.customer.birthDate));
      // this.customerInfo.lineId = cust.customer.lineId;
      // this.customerInfo.howToKnowShop = cust.customer.howToKnowShop;

      // console.log("CUSTOMER INFO >>. ", cust);

      this.customerInfo.name = cust.customer.name;
      this.customerInfo.address = cust.customer.address;
      this.customerInfo.birthDate = new Date(this.formatDate(cust.customer.birthDate));
      this.customerInfo.lineId = cust.customer.lineId;
      this.customerInfo.howToKnowShop = cust.customer.howToKnowShop;
      this.customerInfo.voucher =   cust.maxVoucher;
      this.customerInfo.point =   cust.maxPoint;



      // console.log("customer info from this.customerInfo ", this.customerInfo)
    },

    removeItem(item, index) {
      console.log(item)
      this.$swal({
        title: "ยืนยันการลบ",
        html: "",
        icon: "warning",
        showCancelButton: true
      }).then(confirm => {
        if (confirm.value) {
          this.confirmRemove(item, index);
        }
      });
    },
    confirmRemove(item, index) {
      console.log(`>>> remove item`, item, index);
      const saleProductId = item.saleProductId;
      const voucherId = item.voucherId;
      const state = this.$store.state;

      if (voucherId === undefined) {
        // remove sale product
        const request = this.$customAxios(
          "POST",
          `${state.apiURL}/api/v2/DeleteSaleProduct`,
          { saleProductId: saleProductId },
          state.auth.apiHeader
        );

        request.then(res => {
          console.log(`>>> res delete sale product`, res);
          this.fetchProduct();
        });
      } else {
        // remove voucher
        const request = this.$customAxios(
          "POST",
          `${state.apiURL}/api/v2/DeleteVoucher`,
          { voucherId: voucherId },
          state.auth.apiHeader
        );

        request.then(res => {
          console.log(`>>> res delete voucher`, res);
          this.fetchProduct();
        });
      }
    },
    addProduct() {
      this.$store.dispatch("sidebar/sidebarRightCreateVoucherToggle", false);
      this.$store.dispatch("sidebar/sidebarRightCreateProductToggle", true);
    },
    popupPayment() {
      this.popupPaymentActive = true;
    },
    modalExpandToggle() {
      if (this.modalExpand == "none") {
        this.modalExpand = "block";
      } else {
        this.modalExpand = "none";
      }
    },
    clearPaymentField() {
      this.paymentInfo = {
        customerName: "",
        customerAddress: "",
        customerPhone: "",
        lineId: "",
        birthDate: "",
        howToKnowShop: "",
        accountNumber: "",
        accountName: "",
        bankName: "",
        paymentMethod: "เงินสด",
        remark: ""
      };

      this.validate = {
        customerName: false,
        customerAddress: false,
        customerPhone: false,
        lineId: false,
        birthDate: false
      };
    },
    async submitPayment() {
      // validate fields
      var valid = this.$validator.validate();



        if (valid) {
          const url = `${this.$store.state.apiURL}/api/v2/AddSaleOrder`;
          let cartItems = [];

          this.$store.state.cartSale.cartItems.forEach(item => {
            cartItems.push({
              saleProductId: item.info.saleProductId
                ? item.info.saleProductId
                : null,
              voucherId: item.info.voucherId ? item.info.voucherId : null,
              qty: item.info.quantity
            });
          });

          let custInfo = {};


          custInfo.customerName = this.customerInfo.name;
          custInfo.customerAddress = this.customerInfo.address;
          custInfo.customerPhone = this.customerInfo.phoneNumber;

          custInfo.birthDate = this.formatDate(this.customerInfo.birthDate);
          custInfo.lineId = this.customerInfo.lineId;
          custInfo.howToKnowShop = this.customerInfo.howToKnowShop;

          custInfo.paymentMethod = this.paymentMethod;

          // this.customerInfo.name = cust.customer.name;
          // this.customerInfo.address = cust.customer.address;
          // this.customerInfo.birthDate = new Date(this.formatDate(cust.customer.birthDate));

          // this.customerInfo.lineId = cust.customer.lineId;
          // this.customerInfo.howToKnowShop = cust.customer.howToKnowShop;
          // this.customerInfo.voucher = cust.maxVoucher;
          // this.customerInfo.point = cust.maxPoint;
          this.$vs.loading();
          this.$store.commit("common/BUTTON_LOCK");
          try{
             var res = await axios
            .post(
              url,
              {
                orderDetail: {
                  ...custInfo
                },
                saleOrderItems: cartItems
              },
              this.$store.state.auth.apiHeader
            );

          const data = res.data;
          this.clearPaymentField();
          this.popupPaymentActive = false;

          var weighted_score = this.calculateScoreForAbbReceiptSo(0, this.$store.state.cartSale.cartItems.length);
          var path = "/pages/abb-receipt-so/" + weighted_score.toString()  + "/so/" + data.saleOrderId;
          this.$store.commit("cartSale/resetCart");

          this.$router.push(path);


        //  console.log(data);
        //  console.log("weighted_score >>>> ",weighted_score);

          }catch(err){
            res = null;
            this.$vs.loading.close();
            this.$store.commit("common/BUTTON_UNLOCK");

            return;
          }

          this.$vs.loading.close();

        }
    },

    getRedirectLinkAbbReceipt(){
      //*** 2021-03-09 : BEGIN For ABB RECEIPT

      var weighted_score = this.calculateScoreForAbbReceiptSo(0, this.$store.state.cartSale.cartItems.length);

      //*** 2021-03-09 : END For ABB RECEIPT

      //*** ABB RECEIPT
       var path = "/pages/abb-receipt-so/" + weighted_score.toString()  + "/" + this.orderHeaderId

      //  console.log(' abb rct path >>> ',path);


       return path;
    },
    addItemToCart(item) {
      let itemId = null;

      if (item.saleProductId !== undefined && item.saleProductId !== null) {
        itemId = item.saleProductId;
      }

      if (item.voucherId !== undefined && item.voucherId !== null) {
        itemId = item.voucherId;
      }

      // console.log(">>> add item id ", itemId);
      this.$store.dispatch("cartSale/addCartItem", {
        id: itemId,
        info: {
          ...item
        }
      });
    },
    addVoucher() {
      this.$store.dispatch("sidebar/sidebarRightCreateProductToggle", false);
      this.$store.dispatch("sidebar/sidebarRightCreateVoucherToggle", true);
    },
    onChangeToggleDisplay(state) {
      this.toggleDisplay = state;
      this.fetchProduct();
    },
    onChangePaymentMethod(selectPayment) {
      this.paymentInfo.paymentMethod = selectPayment;
    },
    querySearch() {
      this.fetchProduct();
    },
    async fetchProduct() {

      // console.log("CALL fetchProduct >>> ");

      const url = `${this.$store.state.apiURL}/api/v2/SearchSaleProduct`;
      let res = null;
      res = await axios
        .post(
          url,
          {
            filter: true,
            PageNumberSaleProduct: this.currentPaginate,
            PageSizeSaleProduct: this.pageSize,
            PageNumberVoucher: this.currentVoucherPage,
            PageSizeVoucher: this.pageSize,
            simpleSearch: true,
            keyword: this.searchKeyword
          },
          this.$store.state.auth.apiHeader
        );
        
        let data = res.data;

        // get total items
        this.totalPaginate = Math.round(data.saleItemNumber / this.pageSize);

        // fresh display items
        this.displayItems = [];

          // check current mode between sale-product & voucher
          if (!this.toggleDisplay) {
            // sale-product

            // update path image
            data.saleProducts.forEach((item, index) => {
              // console.log(item, index);
              if (data.saleProducts[index].mainImageId !== null) {
                const imgURL = `${this.$store.state.baseImageStore}SaleProduct/${item.mainImageId}`;
                // const imgURL = this.$store.state.baseImageStore + "SaleProduct/" + item.mainImageId;
                data.saleProducts[index]["mainImage"] = imgURL;
                // console.log(imgURL);
              } else {
                data.saleProducts[index]["mainImage"]  = this.$store.state.defaultImage;
                  // "https://images.unsplash.com/photo-1533090161767-e6ffed986c88?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=458&h=550&q=80";
              }

              this.$set(this.displayItems, index,  data.saleProducts[index]);

            });

            // this.displayItems = data.saleProducts;

            this.pagination = this.$calcPagination(
              data.saleProducts.length,
              this.currentPaginate,
              data.saleItemListNumber,
              data.saleItemNumber
            );
          } else {
            // voucher

            // update path image
            // console.log("data.vouchers >>> ",data.vouchers);

            data.vouchers.forEach((item, index) => {
              // console.log(item, index);
              if (data.vouchers[index].mainImageId !== null) {
                const imgURL = this.$store.state.baseImageStore + "Voucher/" + item.mainImageId;
                data.vouchers[index]["mainImage"] = imgURL;
                console.log(imgURL);
              } else {
                data.vouchers[index]["mainImage"]  = this.$store.state.defaultImage;
                  // "https://images.unsplash.com/photo-1533090161767-e6ffed986c88?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=458&h=550&q=80";
              }

               this.$set(this.displayItems, index,  data.vouchers[index]);

            });

            // this.displayItems = data.vouchers;


            console.log("something change >>> ", this.somethingChange);

            this.pagination = this.$calcPagination(
              data.vouchers.length,
              this.currentPaginate,
              data.voucherItemListNumber,
              data.voucherItemNumber
            );
          }

          console.log(this.pagination);
    
    }
  },
  watch: {
    windowWidth(newWidth, oldHWidth) {
            this.txt = `it changed to ${newWidth} from ${oldHWidth}`;
            this.widthText = newWidth.toString() + "px !important";
            // console.log("this.widthText ****** ",this.widthText);
        },
    async currentPaginate(){
      // console.log(`>>> page update`, newVal);
      await this.fetchProduct();
    },
    async somethingChange(){
      console.log(`WATCH::: somethink change`, );
      this.fetchProduct();
    }
  },
  created() {
    let that = this;

    this.$root.$on("triggerFetchProduct", function() {
      that.onChangeToggleDisplay(false);
    });

    this.$root.$on("triggerFetchVoucher", function() {
      that.onChangeToggleDisplay(true);
    });

    this.$store.commit("productList/RESET_CHANGE");
  },
  beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
  },
  mounted() {
    // console.log(`>>> sale-order page mounted`);
    // console.log(this.$store.state.auth.apiHeader);
    this.fetchProduct();
    window.onresize = () => {
      this.windowWidth = window.innerWidth
    };
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
.btn-filter {
  margin-top: 17px;
}

.input-display-items input[readonly] {
  background-color: #f0f0f0 !important;
}

.product-catalog-table-detail td {
  font-size: 12px;
}

.card-footer .vs-card--content {
  margin-bottom: 0;
}

.payment-date-picker input {
  border: 1px solid #ea5455 !important;
}
</style>

<style lang="scss" scoped>
.scroll-area {
  position: relative;
  margin: auto;
  // height: 1200px;
}

.cardx {
  box-shadow: none !important;
  border: 1px solid #f2f2f2;
}

.ef-btn-payment:active,
.ef-btn-payment:hover {
  box-shadow: none !important;
}

.c-box-shadow:hover {
  box-shadow: 2px 2px 2px 2px #f2f2f2 !important;
}


.reduceBtnTrue{
  width: 32% !important;
  left: 68% !important;
}
.reduceBtnFalse{

 width: 28% !important;
  left: 72% !important;



}
</style>
